import api from '../index'

export default {
  // // 后端获取路由数据
  // routeList: () => api.get('app/route/list', {
  //   baseURL: '/mock/',
  // }),

  // // 基于文件系统路由模式下，后端获取导航菜单数据
  // menuList: () => api.get('app/menu/list', {
  //   baseURL: '/mock/',
  // }),

  // 启动策略
  startStrategy: (data: {
    strategy_name: string
    exchange_params: {
      exchange_name: string
      account: string
      exchange_env: string
    }
    params: object
  }) => api.post('/strategy/start', data),

  // 停止策略
  stopStrategy: (data: {
    strategy_name: string
    exchange_params: {
      exchange_name: string
      account: string
      exchange_env: string
    }
    params: object
  }) => api.post('/strategy/stop', data),

  // 更新策略
  updateStrategy: (data: {
    strategy_name: string
    exchange_params: {
      exchange_name: string
      account: string
      exchange_env: string
    }
    params: object
  }) => api.post('/strategy/change_params', data),

  // 获取正在运行的策略
  getRunning: () => api.get('/strategy/running'),

  // get my dashboardAccount
  getMyDashboardAccount: (
  ) => api.get('/account/me'),

  // 获取用户列表
  getAccount: (data: {
    exchange_name: string
    trade_env: string
  }) => api.post('/account/query_account', data),

  // 获取symbol 列表
  getSymbol: (data: {
    exchange_name: string
  }) => api.post('/exchange/symbol', data),

  // 获取 position 列表
  getPosition: () => api.get('/position/all'),

  // 获取 profit 列表
  getProfit: () => api.get('/profit/all'),

  // 获取 position 列表
  getOptionPosition: () => api.get('/option/position'),

  // 获取 open option order 列表
  getOpenOption: () => api.get('/option/open_order'),

  // 创建 option 订单
  createOptionOrder: (data: {
    symbol: string
    price: string
    amount: string
    side: string
    order_type: string
  }) => api.post('/option/create_order', data),

  // 取消 option 订单
  cancelOptionOrder: (data: {
    symbol: string
    order_id: string
  }) => api.post('/option/cancel_order', data),

  // 获取 option 信息
  // 返回数据格式：
  // {"status":1,"error":"","data":{"option_info":{"BTC":{"240924":[61500.0,61500.0,62000.0]}}}
  getOptionInfo: () => api.get('/option/task/get_info'),

  // 获取 filter 信息
  getFilterInfo: () => api.get('/option/task/get_filter'),

  // 获取 运行中的任务 信息
  getRunningTask: () => api.get('/option/task/query_running'),

  // 删除 运行中的任务
  deleteRunningTask: (data: {
    task_id: string
  }) => api.post('/option/task/delete_task', data),

  // 添加期权任务
  addOptionTask: (data: {
    task_id: string
    task_params: object
  }) => api.post('/option/task/add_task', data),

  // 添加期货策略账号
  futuresAddAccount: (data: {
    strategy: string
    account: string
    amount_type: string
    amount_params: string
  }) => api.post('/futures/running_strategy/add_strategy', data),

  // 更新期货策略账号
  futuresUpdateAccount: (data: {
    strategy: string
    account: string
    amount_type: string
    amount_params: string
  }) => api.post('/futures/running_strategy/update_strategy', data),

  // 删除账号:
  futuresDeleteAccount: (data: {
    strategy: string
    account: string
    amount_type: string
    amount_params: string
  }) => api.post('/futures/running_strategy/delete_strategy', data),

  // 查询运行中策略账号
  futuresQueryAll: () => api.get('/futures/running_strategy/query_all'),

  // 查询目前的账号
  userQueryAll: () => api.get('/user/query_all'),

  // 查询所有策略
  futresQueryStrategy: () => api.get('/futures/strategy/query_all'),

  // 添加用户
  userAdd: (data: {
    ac: string
    ex: string
    kkk: string
    sss: string
  }) => api.post('/user/add', data),
}
