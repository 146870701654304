import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/option',
  component: Layout,
  redirect: '/option/index',
  name: 'option',
  meta: {
    title: '期权',
    i18n: 'route.tabbar',
    icon: 'game-icons:3d-glasses',
  },
  children: [
    {
      path: 'index',
      name: 'optinIdex',
      component: () => import('@/views/option/index.vue'),
      meta: {
        title: '期权策略',
        i18n: 'route.tabbar',
        menu: false,
        breadcrumb: false,
        activeMenu: '/option',
      },
    },
  ],
}

export default routes
