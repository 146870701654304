import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/position',
  component: Layout,
  redirect: '/position/index',
  name: 'position',
  meta: {
    title: '仓位',
    i18n: 'route.tabbar',
    icon: 'game-icons:3d-glasses',
  },
  children: [
    {
      path: 'index',
      name: 'positionIdex',
      component: () => import('@/views/position/index.vue'),
      meta: {
        title: '仓位信息',
        i18n: 'route.tabbar',
        menu: false,
        breadcrumb: false,
        activeMenu: '/position',
      },
    },
  ],
}

export default routes
