import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/future_strategy_manage',
  component: Layout,
  redirect: '/future_strategy_manage/index',
  name: 'future_strategy_manage',
  meta: {
    title: '期货策略管理',
    i18n: 'route.tabbar',
    icon: 'game-icons:3d-glasses',
  },
  children: [
    {
      path: 'index',
      name: 'futureStrategyManageIdex',
      component: () => import('@/views/future_strategy_manage/index.vue'),
      meta: {
        title: '期货策略管理',
        i18n: 'route.tabbar',
        menu: false,
        breadcrumb: false,
        activeMenu: '/future_strategy_manage',
      },
    },
  ],
}

export default routes
