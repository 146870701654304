const __pages_import_0__ = () => import("/src/views/multilevel_menu_example/level2/level3/page2.vue");
const __pages_import_1__ = () => import("/src/views/multilevel_menu_example/level2/level3/page1.vue");
const __pages_import_2__ = () => import("/src/views/multilevel_menu_example/level2/page.vue");
const __pages_import_3__ = () => import("/src/views/tv/create_tv.vue");
const __pages_import_4__ = () => import("/src/views/task_option/index.vue");
const __pages_import_5__ = () => import("/src/views/tab_example/index.vue");
const __pages_import_6__ = () => import("/src/views/position/index.vue");
const __pages_import_7__ = () => import("/src/views/option/index.vue");
const __pages_import_8__ = () => import("/src/views/multilevel_menu_example/page.vue");
const __pages_import_9__ = () => import("/src/views/future_strategy_manage/index.vue");
const __pages_import_10__ = () => import("/src/views/reload.vue");
const __pages_import_11__ = () => import("/src/views/login.vue");
import __pages_import_12__ from "/src/views/index.vue";
const __pages_import_13__ = () => import("/src/views/[...all].vue");

const routes = [{"name":"multilevel_menu_example-level2-level3-page2","path":"/multilevel_menu_example/level2/level3/page2","component":__pages_import_0__,"props":true,"meta":{"title":"导航2-2-2"}},{"name":"multilevel_menu_example-level2-level3-page1","path":"/multilevel_menu_example/level2/level3/page1","component":__pages_import_1__,"props":true,"meta":{"title":"导航2-2-1"}},{"name":"multilevel_menu_example-level2-page","path":"/multilevel_menu_example/level2/page","component":__pages_import_2__,"props":true,"meta":{"title":"导航2-1"}},{"name":"tv-create_tv","path":"/tv/create_tv","component":__pages_import_3__,"props":true,"meta":{"enabled":false}},{"name":"task_option","path":"/task_option","component":__pages_import_4__,"props":true,"meta":{"enabled":false}},{"name":"tab_example","path":"/tab_example","component":__pages_import_5__,"props":true,"meta":{"enabled":false}},{"name":"position","path":"/position","component":__pages_import_6__,"props":true,"meta":{"enabled":false}},{"name":"option","path":"/option","component":__pages_import_7__,"props":true,"meta":{"enabled":false}},{"name":"multilevel_menu_example-page","path":"/multilevel_menu_example/page","component":__pages_import_8__,"props":true,"meta":{"title":"导航1"}},{"name":"future_strategy_manage","path":"/future_strategy_manage","component":__pages_import_9__,"props":true,"meta":{"enabled":false}},{"name":"reload","path":"/reload","component":__pages_import_10__,"props":true},{"name":"login","path":"/login","component":__pages_import_11__,"props":true,"meta":{"title":"登录","constant":true,"layout":false}},{"name":"home","path":"/","component":__pages_import_12__,"props":true,"meta":{"title":"主页","icon":"ant-design:home-twotone"}},{"name":"notFound","path":"/:all(.*)*","component":__pages_import_13__,"props":true,"meta":{"title":"找不到页面","constant":true,"layout":false}}];

export default routes;