<route lang="yaml">
name: home
meta:
  title: 主页
  icon: ant-design:home-twotone
</route>

<script setup lang="ts">
import VChart from '@visactor/vchart'
import api from '@/api/modules/app'

const chart2Ref = ref()
const chart3Ref = ref()
const chart4Ref = ref()
let chart2: any
let chart3: any
let chart4: any

const totalProfitInfo = ref([])
const selectedAccount = ref('')
const profitLines = ref([])
const strategyProfitLines = ref([])
const strategyRate = ref([])

async function getProfitList() {
  const res = await api.getProfit()
  totalProfitInfo.value = res.data
  // profitLines.value = res.data.profit_list
  // strategyProfitLines.value = res.data.strategy_profit
  // strategyRate.value = res.data.strategy_rate
  // console.log(profitLines.value)
}

async function mergeAllAccountsData() {
  const allProfit = {
    profit_list: [],
    strategy_profit: {},
    strategy_rate: {},
  }

  // 合并所有账户的数据
  Object.values(totalProfitInfo.value).forEach((account: any) => {
    // 合并 profit_list
    allProfit.profit_list = [...allProfit.profit_list, ...account.profit_list]

    // 合并 strategy_profit
    account.strategy_profit.forEach((strategy: any) => {
      if (!allProfit.strategy_profit[strategy.strategy_name]) {
        allProfit.strategy_profit[strategy.strategy_name] = 0
      }
      allProfit.strategy_profit[strategy.strategy_name] += strategy.profit
    })

    // 合并 strategy_rate
    account.strategy_rate.forEach((strategy: any) => {
      if (!allProfit.strategy_rate[strategy.strategy_name]) {
        allProfit.strategy_rate[strategy.strategy_name] = 0
      }
      allProfit.strategy_rate[strategy.strategy_name] += strategy.rate
    })
  })

  // 按照 datetime 排序
  allProfit.profit_list.sort((a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime())

  // 转换合并后的数据格式
  return {
    profit_list: allProfit.profit_list,
    strategy_profit: Object.entries(allProfit.strategy_profit).map(([strategy_name, profit]) => ({
      strategy_name,
      profit,
    })),
    strategy_rate: Object.entries(allProfit.strategy_rate).map(([strategy_name, rate]) => ({
      strategy_name,
      rate: rate / Object.keys(totalProfitInfo.value).length, // 计算平均胜率
    })),
  }
}

// 添加一个处理时间序列数据的函数
function processTimeSeriesData(profitData) {
  // 创建一个 Map 来存储每个小时的数据
  const hourlyDataMap = new Map()

  // 处理每个数据点
  profitData.forEach((item) => {
    // 将时间字符串转换为 Date 对象，并设置分钟和秒为0
    const date = new Date(item.datetime)
    date.setMinutes(0, 0, 0)
    const hourKey = date.getTime()

    if (!hourlyDataMap.has(hourKey)) {
      hourlyDataMap.set(hourKey, {
        datetime: date.toISOString(),
        values: new Map(), // 用于存储不同 type 的值
      })
    }

    const hourData = hourlyDataMap.get(hourKey)
    if (!hourData.values.has(item.type)) {
      hourData.values.set(item.type, item.value)
    }
    else {
      // 如果同一小时内有多个值，取最后一个
      hourData.values.set(item.type, item.value)
    }
  })

  // 将 Map 转换为数组，并按时间排序
  const sortedData = Array.from(hourlyDataMap.entries())
    .sort(([timeA], [timeB]) => timeA - timeB)

  // 转换为图表所需的格式
  const result = []
  sortedData.forEach(([_, data]) => {
    data.values.forEach((value, type) => {
      result.push({
        datetime: data.datetime,
        value,
        type,
      })
    })
  })

  return result
}

// 添加计算总盈利曲线的函数
function calculateTotalProfitLine(profitData: { datetime: string, value: number, type: string }[]) {
  // 按时间排序
  const sortedData = [...profitData].sort((a, b) =>
    new Date(a.datetime).getTime() - new Date(b.datetime).getTime(),
  )

  // 按时间点和类型分组,获取每个时间点下各类型的最新值
  const result = []
  sortedData.forEach((currentItem) => {
    const currentTime = new Date(currentItem.datetime).getTime()
    const typeMap = new Map()

    // 遍历所有数据,找出当前时间点之前每个类型的最新值
    sortedData.forEach((item) => {
      const itemTime = new Date(item.datetime).getTime()
      if (itemTime <= currentTime) {
        typeMap.set(item.type, item.value)
      }
    })

    // 计算当前时间点所有类型值的总和
    const totalProfit = Array.from(typeMap.values()).reduce((sum, value) => sum + value, 0)

    // 添加总盈利数据点
    result.push({
      datetime: currentItem.datetime,
      value: totalProfit,
      type: '总盈利',
    })
  })

  return [...profitData, ...result]
}

// 修改折线图配置
async function initChart2() {
  await getProfitList()

  // 添加 "all" 选项作为默认选择
  selectedAccount.value = 'all'

  if (selectedAccount.value === 'all') {
    const allData = await mergeAllAccountsData()
    profitLines.value = allData.profit_list
    strategyProfitLines.value = allData.strategy_profit
    strategyRate.value = allData.strategy_rate
  }
  else if (Object.keys(totalProfitInfo.value).length > 0) {
    // 确保在初始化时有默认选中的账号和数据
    selectedAccount.value = Object.keys(totalProfitInfo.value)[0]
    profitLines.value = totalProfitInfo.value[selectedAccount.value].profit_list
    strategyProfitLines.value = totalProfitInfo.value[selectedAccount.value].strategy_profit
    strategyRate.value = totalProfitInfo.value[selectedAccount.value].strategy_rate
  }

  // 处理时间序列数据
  const processedData = processTimeSeriesData(profitLines.value)
  const chartData = calculateTotalProfitLine(processedData)

  // 折线图配置
  const spec = {
    type: 'line',
    data: [{
      id: 'data',
      values: chartData,
    }],
    xField: 'datetime',
    yField: 'value',
    seriesField: 'type',
    point: {
      visible: false,
    },
    line: {
      style: {
        lineWidth: (datum) => {
          return datum.type === '总盈利' ? 3 : 2
        },
      },
    },
    color: {
      field: 'type',
      value: (type) => {
        return type === '总盈利' ? '#ff0000' : '#1890ff'
      },
    },
    legends: { visible: true },
  }

  // 柱状图配置
  const spec1 = { // 移除 ref 包装
    type: 'bar',
    width: 1000,
    direction: 'horizontal',
    data: [{
      id: 'data',
      values: strategyProfitLines.value,
      fields: {
        profit: {
          sortIndex: 1,
          sortReverse: true,
        },
      },
    }],
    xField: 'profit',
    yField: 'strategy_name',
    axes: [
      { orient: 'left', type: 'band' },
      { orient: 'bottom', type: 'linear' },
    ],
    title: {
      visible: false,
      text: '盈亏排名',
    },
  }

  // 第三个图表配置
  const spec2 = { // 移除 ref 包装
    type: 'bar',
    width: 1000,
    direction: 'horizontal',
    data: [
      {
        id: 'data',
        values: strategyRate.value,
        fields: {
          rate: {
            sortIndex: 1,
            sortReverse: true,
          },
        },
      },
    ],
    xField: 'rate',
    yField: 'strategy_name',
    axes: [
      { orient: 'left', type: 'band' },
      { orient: 'bottom', type: 'linear' },
    ],
    title: {
      visible: false,
      text: '胜率排名',
    },
  }

  try {
    chart2 = new VChart(spec, { dom: chart2Ref.value })
    chart2.renderSync()

    chart3 = new VChart(spec1, { dom: chart3Ref.value })
    chart3.renderSync()

    chart4 = new VChart(spec2, { dom: chart4Ref.value })
    chart4.renderSync()
  }
  catch (error) {
    console.error('图表初始化失败:', error)
  }
}

// getProfitList()

onMounted(() => {
  setTimeout(() => {
    initChart2()
  }, 100)
})

watch(selectedAccount, async (newValue) => {
  if (!newValue) {
    return
  }

  try {
    if (newValue === 'all') {
      const allData = await mergeAllAccountsData()
      profitLines.value = allData.profit_list
      strategyProfitLines.value = allData.strategy_profit
      strategyRate.value = allData.strategy_rate
    }
    else if (totalProfitInfo.value[newValue]) {
      profitLines.value = totalProfitInfo.value[newValue].profit_list
      strategyProfitLines.value = totalProfitInfo.value[newValue].strategy_profit
      strategyRate.value = totalProfitInfo.value[newValue].strategy_rate
    }

    // 更新图表数据
    if (chart2 && profitLines.value) {
      const processedData = processTimeSeriesData(profitLines.value)
      const chartData = calculateTotalProfitLine(processedData)
      chart2.updateData('data', chartData)
      chart2.renderSync()
    }

    if (chart3 && strategyProfitLines.value) {
      const totalProfit = strategyProfitLines.value.reduce((sum, item) => sum + item.profit, 0)
      const chartData = [
        ...strategyProfitLines.value,
        { strategy_name: '总盈利', profit: totalProfit },
      ]
      chart3.updateData('data', chartData)
      chart3.renderSync()
    }

    if (chart4 && strategyRate.value) {
      const avgRate = strategyRate.value.reduce((sum, item) => sum + item.rate, 0) / strategyRate.value.length
      const chartData = [
        ...strategyRate.value,
        { strategy_name: '平均胜率', rate: avgRate },
      ]
      chart4.updateData('data', chartData)
      chart4.renderSync()
    }
  }
  catch (error) {
    console.error('图表更新失败:', error)
  }
})
</script>

<template>
  <div>
    <PageHeader>
      <template #title>
        <div class="flex items-center gap-4">
          欢迎来到策略平台
        </div>
      </template>
      <template #content>
        <div class="text-sm/6">
          <div>
            此页面会展示盈亏信息
          </div>
        </div>
      </template>
    </pageheader>

    <PageMain class="ecology">
      <PageMain title="账号选择" style="margin: 10px 0;">
        <el-select v-model="selectedAccount" placeholder="Select" size="large">
          <el-option
            value="all"
            label="全部账户"
          />
          <el-option
            v-for="account in Object.keys(totalProfitInfo)"
            :key="account"
            :value="account"
            :label="account"
          />
        </el-select>
      </PageMain>

      <ElCol :md="12">
        <PageMain title="策略收益" style="margin: 10px 0;">
          <div ref="chart2Ref" style="width: 100%; height: 400px;" />
        </PageMain>
      </ElCol>

      <ElCol :md="12">
        <PageMain title="盈亏排名" style="margin: 10px 0;">
          <div ref="chart3Ref" style="width: 100%; height: 400px;" />
        </PageMain>
      </ElCol>
      <ElCol :md="12">
        <PageMain title="胜率排名" style="margin: 10px 0;">
          <div ref="chart4Ref" style="width: 100%; height: 400px;" />
        </PageMain>
      </ElCol>
    </PageMain>
  </div>
</template>

<style lang="scss" scoped>
.text-emphasis {
  text-emphasis-style: "❤";
}

.ecology {
  --at-apply: flex-1 m-0;

  :deep(.title-container) {
    --at-apply: flex items-center justify-between flex-wrap gap-4;

    .title-info {
      --at-apply: flex items-center gap-4;

      img {
        --at-apply: block w-12 h-12;
      }

      h1 {
        --at-apply: m-0 text-2xl;
      }

      h2 {
        --at-apply: m-0 text-base text-stone-5 font-normal;
      }
    }
  }
}
</style>
