import { useAuth0 } from '@auth0/auth0-vue'
import useSettingsStore from './settings'
import useRouteStore from './route'
import useMenuStore from './menu'
import router from '@/router'

const useUserStore = defineStore(
  'user',
  () => {
    const { user, isAuthenticated, getAccessTokenSilently, logout: auth0Logout, loginWithRedirect } = useAuth0()
    const settingsStore = useSettingsStore()
    const routeStore = useRouteStore()
    const menuStore = useMenuStore()

    // 添加 watch 来监听 Auth0 状态变化
    watch([isAuthenticated, user], async ([newIsAuthenticated, newUser]) => {
      console.log(isAuthenticated, "?????")
      if (newIsAuthenticated && newUser) {
        // 如果已认证但没有 token，尝试重新登录
        if (!token.value) {
          await login()
        }
      }
    }, { immediate: true })

    const account = ref(sessionStorage.account ?? '')
    const token = ref(sessionStorage.token ?? '')
    const avatar = ref(sessionStorage.avatar ?? '')
    const permissions = ref<string[]>([])
    // 使用 Auth0 的认证状态
    const isLogin = computed(() => {
      return ((isAuthenticated.value && !!token.value) || (token.value && account.value))
    })

    // 登录 - 使用 Auth0 token
    async function login() {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: 'openid profile email',  // 请求的权限范围
          },
          detailedResponse: true  // 获取详细响应，包括 id_token
        })
        console.log(accessToken, "accessToken!!!")

        // 存储用户信息
        const userData = {
          account: user.value?.email || '',
          token: accessToken.access_token,
          avatar: user.value?.picture || '',
        }
        console.log(userData, "userdData-login!!");
        
        sessionStorage.setItem('account', userData.account)
        sessionStorage.setItem('token', userData.token)
        sessionStorage.setItem('avatar', userData.avatar)
        
        account.value = userData.account
        token.value = userData.token 
        avatar.value = userData.avatar

        return userData
      } catch (error) {
        console.error('Auth0 登录失败:', error)
        loginWithRedirect()
        // throw error
      }
    }

    // 登出 - 调用 Auth0 登出
    async function logout(redirect = router.currentRoute.value.fullPath) {
      // 清除本地存储
      sessionStorage.removeItem('account')
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('avatar')
      
      // 清除状态
      account.value = ''
      token.value = ''
      avatar.value = ''
      permissions.value = []
      
      // 清除路由
      routeStore.removeRoutes()
      menuStore.setActived(0)

      // 调用 Auth0 登出
      await auth0Logout({
        logoutParams: {
          returnTo: window.location.origin
        }
      })

      // 重定向到登录页
      router.push({
        name: 'login',
        query: {
          ...(router.currentRoute.value.path !== settingsStore.settings.home.fullPath && 
              router.currentRoute.value.name !== 'login' && { redirect }),
        },
      })
    }

    // 获取权限 - 从 Auth0 用户信息中获取
    async function getPermissions() {
      // 从 Auth0 user 对象中获取权限
      const userPermissions = user.value?.['https://your-namespace/permissions'] || []
      permissions.value = userPermissions
      return {
        data: {
          permissions: userPermissions
        }
      }
    }

    return {
      account,
      token,
      avatar,
      permissions,
      isLogin,
      login,
      logout,
      getPermissions,
      user: computed(() => user.value)
    }
  }
)

export default useUserStore